.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 50px;
  width: 100%;
}

.carousel-wrapper::-webkit-scrollbar {
 display: none;
 scroll-behavior: smooth;
}

.carousel {
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  text-align: center;
  transition: transform 0.5s ease-in-out, flex 0.5s ease-in-out;
  border-radius: 13.176px;
  background: rgba(46, 46, 46, 0.9);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.35),
    0px 1px 5px 0px rgba(139, 107, 25, 0.5) inset,
    0px 1px 5px 0px rgba(238, 238, 238, 0.35);
  backdrop-filter: blur(9.938556671142578px);
  margin: 0 30px;
  width: 10vw;
}

.carousel-item.center {
  transform: scale(1.5);
}

.carousel-image {
  width: 800px;
  height: auto;
}

.carousel-caption {
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.carousel-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}


@media (max-width: 765px) {
 .carousel-item {
  width: 30vw;
}
}